const random = (min, max, isFloat = false) => {
    const result = Math.random() * (max - min) + min;
    return isFloat ? result : Math.round(result);
};


export const formatExternalVideo = (video) => ({
    date: video.node.createdAt,
    hash: video.node.id,
    poster: video.node.poster,
    title: video.node.title,
    products: video.node.products,
    url: `https://youtu.be/${video.node.videoId}`,
    provider: video.node.provider,
    videoId: video.node.videoId,
});

export const formatProjectVideo = (video) => ({
    date: video.createdAt,
    hash: video.id,
    poster: video.poster,
    rating: random(3, 5, true),
    title: video.productTitle,
    url: video.productUrl,
    views: random(0, 350),
    provider: 'VIDEO',
    ratio: video.ratio,
});
