<template>
  <div
    class="main_card noselect"
    @mouseenter="shadow=false"
    @mouseleave="shadow=true"
    :class="{shadowbox: shadow}"
  >
    <v-layout>
      <div style="position:relative;height:136px;" class="pointer" @click="onClick">
        <img style="position:absolute;" class="main_card_img" :src="video.poster" />
        <a style="position:absolute;">
          <play
            id="play"
            :class="{play_scale_down: !shadow}"
            style="transition: transform .3s ease-in-out"
          />
        </a>
      </div>
      <!-- Share -->
      <transition name="fade">
        <div style="position:relative;height:136px;z-index:1;" v-if="socialMedia">
          <img
            style="position:absolute;z-index:-1"
            class="main_card_img"
            src="@/assets/blur.jpg"
          />
          <a style="position:absolute;">
            <cross class="pointer" id="cross" @click="socialMedia=false" />
          </a>
          <v-layout align-center justify-center row fill-height style="z-index:10;width:238px;">
            <social-sharing
              @open="onShare"
              :url="shareUrl"
              :networks="overriddenNetworks"
              inline-template
            >
              <div>
                <network network="facebook">
                  <font-awesome-icon
                    onmouseleave="this.style.boxShadow = 'rgba(0, 0, 0, 0.3) 5px 5px 5px -2px';"
                    onmouseenter="this.style.boxShadow = 'rgba(0, 0, 0, 0.3) 1px 1px 1px -2px';"
                    :icon="['fab', 'facebook-f']"
                    :style="{transition: 'all 0.8s ease-in-out', background: 'rgb(61,90,150)',color: 'white', 'font-size': '24px', width: '45px', height: '45px', 'padding': '12px', 'border-radius': '100%','box-shadow': 'rgba(0, 0, 0, 0.3) 7px 9px 28px -2px', 'cursor': 'pointer' }"
                  />
                </network>
                <network network="twitter">
                  <font-awesome-icon
                    onmouseleave="this.style.boxShadow = 'rgba(0, 0, 0, 0.3) 5px 5px 5px -2px';"
                    onmouseenter="this.style.boxShadow = 'rgba(0, 0, 0, 0.3) 1px 1px 1px -2px';"
                    :icon="['fab', 'twitter']"
                    :style="{transition: 'all 0.8s ease-in-out', background: 'rgb(57,154,213)',color: 'white', 'font-size': '24px', width: '45px', height: '45px', 'padding': '12px', 'border-radius': '100%','box-shadow': 'rgba(0, 0, 0, 0.3) 7px 9px 28px -2px','margin-left':'20px', 'cursor': 'pointer' }"
                  />
                </network>
                <network network="whatsappFix">
                  <font-awesome-icon
                    onmouseleave="this.style.boxShadow = 'rgba(0, 0, 0, 0.3) 5px 5px 5px -2px';"
                    onmouseenter="this.style.boxShadow = 'rgba(0, 0, 0, 0.3) 1px 1px 1px -2px';"
                    :icon="['fab', 'whatsapp']"
                    :style="{transition: 'all 0.8s ease-in-out', background: 'rgb(50,186,70)',color: 'white', 'font-size': '30px', width: '45px', height: '45px', 'padding': '12px', 'border-radius': '100%','box-shadow': 'rgba(0, 0, 0, 0.3) 7px 9px 28px -2px', 'margin-left':'20px', 'cursor': 'pointer' }"
                  />
                </network>
              </div>
            </social-sharing>
          </v-layout>
        </div>
      </transition>
    </v-layout>
    <v-layout align-center justify-center row class="info_container">
      <v-flex grow>
        <div class="main_card_title_container" >
          <p
            @click="onClick"
            class="card_text pointer"
            style="transition: all 0.4s ease-in-out"
            :style="{color: params.config.COLOR_CARD, opacity: (socialMedia) ? '.5' : '1' , filter: `grayscale(${socialMedia ? '1' : '0'})`}"
          >
            {{ trim(video.title) }}
          </p>
        </div>
      </v-flex>
      <v-flex
        shrink
        style="flex: none; transition: transform .8s ease-in-out"
        :class="{rotateShare: socialMedia}"
      >
        <a id="main_card_icon_share">
          <share
            style="transition: all .8s ease-in-out"
            :class="{share: !socialMedia, share_inc: socialMedia}"
            @click="socialMedia=!socialMedia"
            class="pointer"
            :style="{color: params.config.COLOR_ICON, stroke: params.config.COLOR_ICON, fill: params.config.COLOR_ICON}"
          />
        </a>
      </v-flex>
      <v-flex shrink v-if="video.url" style="flex: none;">
        <a id="main_card_icon_cart" @click="onProduct">
          <cart
            style="transition: opacity .4s ease-in-out, filter .8s ease-in-out"
            id="cart"
            class="pointer"
            @click="socialMedia=false"
            :style="{color: params.config.COLOR_ICON, stroke: params.config.COLOR_ICON, fill: params.config.COLOR_ICON, filter: `grayscale(${socialMedia ? '1' : '0'})`}"
          />
        </a>
      </v-flex>
    </v-layout>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.rotateShare {
  transform: rotate(360deg);
}
</style>

<script>
import share from "../assets/share.svg";
import cart from "../assets/basket.svg";
import cross from "../assets/cross.svg";
import play from "../assets/play.svg";
import { PLAYER_URL } from "../config";

export default {
  name: "Card",
  props: ["params", "video"],
  components: {
    share,
    cart,
    cross,
    play
  },
  computed: {
    shareUrl () {
      return `${PLAYER_URL}#id=${this.video.hash}`;
    },
  },
  methods: {
    onClick () {
      this.$emit('click', this.video);
    },
    onShare () {
      this.$emit('share', this.video);
    },
    onProduct () {
      this.$emit('product', this.video);
    },
    trim (text) {
      return (text.length <= 26) ? text : text.substring(0, 26) + "...";
    },
  },
  data() {
    return {
      socialMedia: false,
      shadow: true,
      facebook: true,
      twitter: true,
      whatsapp: true,
      overriddenNetworks: {
        whatsappFix: {
          sharer: `https://api.whatsapp.com/send?text=@url`,
          type: "popup",
          action: "share/whatsapp/share"
        }
      }
    };
  },
};
</script>
