<template>
  <div
    class="loaderOpacity"
    :class="{'opacity100' : !loading }"
    :style="{ fontFamily: config.FONT_NAME || '', maxWidth: params.args.width, margin: '0 auto' }"
  >
    <mobile v-if="isMobile()" :params="params" />
    <desktop v-else :params="params" />
  </div>
</template>

<script>
import isMobile from "is-mobile";

import desktop from "./view/desktop.vue";
import mobile from "./view/mobile.vue";

export default {
  name: "App",
  components: {
    desktop,
    mobile
  },
  props: ["args", "videos", "config", "categories"],
  methods: {
    isMobile,
  },
  data() {
    return {
      loading: true,
      params: {
        config: this.config,
        videos: this.videos,
        categories: this.categories,
        args: this.args
      }
    };
  },
  created() {
    var self = this;

    const videosResp = this.videos;
    const configResp = this.config;

    if (!videosResp.error) self.params.videos = videosResp;
    if (!configResp.error) self.params.config = configResp;

    let that = this;
    setTimeout(() => {
      that.loading = false;
    }, 1000);
  }
};
</script>

<style>
@import "./assets/styles/card.css";
@import "../node_modules/@skeepers/skeepers-ui-core/dist/skeepers-ui/skeepers-ui.css";

html {
	background: #f6f6f6;
}
</style>
<style scoped>
.application {
  background: #f6f6f6;
}
.loaderOpacity {
  opacity: 0;
  transition: 0.5s ease;
}
.opacity100 {
  opacity: 1;
}
</style>
