import { GRAPHQL_URL } from '../config';
import getExternalVideosGQL from './getExternalVideos.graphql';
import getVideosGQL from './getVideos.graphql';
import getSolutionConfigurationGQL from './getSolutionConfiguration.graphql';

const requests = {};

export const fetchGQL = (query, variables, { cancellable = false, ignoreCache = false } = {}) => {
    const request = query.replace(/\s+/g, ' ').trim();
    const controller = new AbortController();
    if (requests[request] && cancellable) {
        requests[request].abort();
    }
    requests[request] = controller;

    const url = new URL(GRAPHQL_URL);
    url.searchParams.set('query', request);
    url.searchParams.set('variables', JSON.stringify(variables));
    if (!ignoreCache) {
        url.searchParams.set('cacheduration', 15 * 60); // 15 minutes
    }

    return fetch(url.href, {
        signal: controller.signal,
        method: 'GET',
        headers: {
            'apollographql-client-name': 'vsg',
            'apollographql-client-version': process.env.CI_COMMIT_SHORT_SHA || 'local',
        },
    }).then((res) => {
        delete requests[request];
        return res.json();
    });
};

export const getVideos = (id, categories) => fetchGQL(getVideosGQL.loc.source.body, {
    id,
    ...(categories.length ? { categories } : {}),
}, { cancellable: true, ignoreCache: true  });

export const getSolutionConfiguration = (id, categories) => fetchGQL(getSolutionConfigurationGQL.loc.source.body, {
    id,
    ...(categories.length ? { categories } : {}),
});

export const getExternalVideos = (id, categories) => fetchGQL(getExternalVideosGQL.loc.source.body, {
    playerId: id,
    ...(categories.length ? { categories } : {}),
}, { cancellable: true });
