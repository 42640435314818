import { TRACKING_API } from './config';

const sendEvent = async (eventType, parameters = {}) => (fetch(`${TRACKING_API}collect`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ ...parameters, event: eventType }),
    headers: { 'Content-Type': 'application/json' }
}));

const sendSDKEvent = data => {
    if (window.parent && window.parent !== window) {
        window.parent.postMessage(
            JSON.stringify(data),
            { origin: '', ...parseArgs(window.location.hash) }.origin || '*'
        );
    }
};

/* --------------------------------------------------------------------------- */

/* Parse arguments */
export const parseArgs = query => {
    let out = {};
    let data = query.slice(1).split('&');
    data.forEach(e => {
        let spl = e.split('=');
        out[spl[0]] = decodeURIComponent(spl.slice(1).join('='));
    });
    delete out[''];
    return out;
};

/* Save Ambassador click */
export const saveAmbassador = video_id => sendEvent('VIDEO_CTA_JOIN_COMMUNITY', { project_id: video_id });

/* Save Products click */
export const saveProduct = video_id => sendEvent('VIDEO_CTA_BUY_PRODUCT', { project_id: video_id });

/* Save rating (rating between 0 and 5) */
export const saveRating = (video_id, rating = 5) => sendEvent('VIDEO_RATING', { project_id: video_id, rating });

/* Save share click */
export const saveShare = video_id => sendEvent('VIDEO_SHARE', { project_id: video_id });

/* Save VSG_HOME (Tracking) */
/* Mounted app.js pass trackingKey in config */
export const saveVSGHome = vsg_id => sendEvent('VSG_HOMEPAGE_VIEW', { vsg_id });

/* Send RESIZE event (SDK) */
/* When dom change or resize */
export const sendResize = () => {
    const body = document.body;
    const html = document.documentElement;
    const height = html.offsetHeight
        ? html.offsetHeight
        : Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        );
    sendSDKEvent({ type: 'RESIZE', data: `${height}px` });
};

/* Send OPEN_VIDEO event (SDK) */
/* Open popup */
export const openVideo = (video, videos = [], vsg_id) => {
    sendSDKEvent({ type: 'OPEN_VIDEO', data: {
        vsg_id,
        video,
        videos: [...videos],
    } });
};

export const getSuggestions = (currentVideo, videos) => videos
    .filter((video) => video.hash !== currentVideo.hash)
    .sort(() => Math.random() - 0.5)
    .slice(0, 6);

export const scrollUp = () => {
    sendSDKEvent({ type: 'SCROLL', data: {} });
}

export const openVideoLink = (link) => {
    sendSDKEvent({ type: 'OPEN_LINK', data: link });
};
