<template>
  <!-- DEFAULT_CATEGORIES is set in the main.js file if categories are passed as inputs to vsg -->
  <skp-multiselect v-if="categories && categories.length"
      :values.prop="params.config.DEFAULT_CATEGORIES"
      :options.prop="categories"
      :placeholder.prop="params.config.SEARCH_CATEGORIES_PLACEHOLDER"
      :activePlaceholder.prop="params.config.SEARCH_CATEGORIES_PLACEHOLDER"
      @skpSelectChange="$event => onChange($event)"
  >
  </skp-multiselect>
</template>

<style scoped>
  skp-multiselect {
    width: 175px;
  }
</style>

<script>
export default {
  name: "FilterCategories",
  methods: {
    onChange (e) {
      this.$emit('input', e.detail);
    },
  },
  computed: {
    categories() {
      return this.params.categories.map((category) => ({
        text: category,
        value: category,
      }));
    },
  },
  props: ["value", "params"],
};
</script>
