var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.paginatedVideos.length > 0)?_c('div',[_c('v-layout',{staticStyle:{"gap":"16px"},attrs:{"wrap":"","row":"","fill-height":""}},[_vm._l((_vm.paginatedVideos),function(video,index){return _c('card',{key:video.id,class:{transition_start: !_vm.loaded, transition_end: _vm.loaded},style:({transition: ("all 0.8s ease-in-out " + (index / 20) + "s")}),attrs:{"video":video,"params":_vm.params},on:{"click":_vm.onClick,"share":_vm.onShare,"product":_vm.onProduct}})}),_vm._l((4),function(index){return _c('div',{key:index,staticStyle:{"width":"238px","height":"0px"}})})],2),(_vm.pages.pages.length > 1)?_c('v-layout',{style:({
      gap: '1em',
      marginBottom: '40px',
      minHeight: '60px',
      overflowX: 'auto',
      alignItems: 'center',
      color: _vm.params.config.COLOR_NAV,
    }),attrs:{"justify-center":"","align-center":""}},[(_vm.pages.previousButton)?_c('button',{on:{"click":function($event){return _vm.onPage(_vm.page - 1)}}},[_vm._v("<")]):_vm._e(),(_vm.pages.firstPage)?_c('button',{on:{"click":function($event){return _vm.onPage(_vm.pages.firstPage.index)}}},[_vm._v(_vm._s(_vm.pages.firstPage.index))]):_vm._e(),(_vm.pages.leadingDots)?_c('span',[_vm._v("...")]):_vm._e(),_vm._l((_vm.pages.pages),function(page){return _c('button',{key:page.index,style:({
        backgroundColor: page.current ? _vm.params.config.COLOR_ICON : 'white',
        color: page.current ? 'white' : 'inherit',
      }),on:{"click":function($event){return _vm.onPage(page.index)}}},[_vm._v("\n      "+_vm._s(page.index)+"\n    ")])}),(_vm.pages.trailingDots)?_c('span',[_vm._v("...")]):_vm._e(),(_vm.pages.lastPage)?_c('button',{on:{"click":function($event){return _vm.onPage(_vm.pages.lastPage.index)}}},[_vm._v(_vm._s(_vm.pages.lastPage.index))]):_vm._e(),(_vm.pages.nextButton)?_c('button',{on:{"click":function($event){return _vm.onPage(_vm.page + 1)}}},[_vm._v(">")]):_vm._e()],2):_vm._e()],1):_c('div',{attrs:{"id":"empty"}},[_c('empty'),_c('h1',[_vm._v(_vm._s(_vm.params.config.EMPTY_TITLE))]),_c('p',[_vm._v(_vm._s(_vm.params.config.EMPTY_DESCRIPTION))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }