<template>
  <main>
    <template v-if="route === 'menu'">
      <div class="css-layout">
        <Header :params="params" />
        <div class="filters">
          <FilterCategories :params="params" @input="event => onCategory(event)" />
          <Search :params="params" v-model="search" />
        </div>
      </div>
      <CardsList
        v-if="!isLoading"
        :params="params"
        :videos="filteredVideos"
        @video="onVideo"
        @share="onShare"
        @product="onProduct"
        @page="onPage"
      />
      <div class="loader-container" v-else>
        <skp-loader type="secondary" size="large" ></skp-loader>
      </div>
    </template>
    <template v-else-if="route === 'video'">
      <Video
        :params="params"
        :ratings="ratings"
        :video="currentVideo"
        @menu="onMenu"
        @video="onVideo"
        @ambassador="onAmbassador"
        @rate="onRate"
        @share="onShare"
        @product="onProduct"
      />
    </template>
  </main>
</template>

<script>
import Search from "../components/search.vue";
import FilterCategories from "../components/filter_categories.vue";
import Header from "../components/header.vue";
import CardsList from "../components/cards_list.vue";
import Video from "../components/mobileVideo";
import { scrollUp, saveRating, saveShare, saveProduct, saveAmbassador, openVideoLink } from "../events";
import { formatExternalVideo, formatProjectVideo } from '../utils';
import { getExternalVideos, getVideos } from '../graphql';
import { allVideos } from '../main';

export default {
  name: "Mobile",
  props: ["params"],
  data() {
    return {
      isLoading: false,
      search: '',
      route: 'menu',
      currentVideo: null,
      ratings: {},
    };
  },
  components: {
    Header,
    CardsList,
    Video,
    FilterCategories,
    Search,
  },
  computed: {
    filteredVideos () {
      const search = this.search.trim();
      return search ? this.params.videos.filter((video) => video.title.toLowerCase().includes(search.toLowerCase())) : this.params.videos;
    },
  },
  methods: {
    async onCategory (event) {
      try {
        this.isLoading = true;
        const [
          { data: { getSolution: videos } = {} },
          { data: { externalVideos } = {} }
        ] = await Promise.all([
          getVideos(this.params.args.hash, event),
          getExternalVideos(this.params.args.hash, event)
        ]);
        this.isLoading = false;
        this.params.videos = event.length === 0
            ? allVideos
            : videos?.videos
                ?.map((video) => formatProjectVideo(video))
                .concat(externalVideos?.edges?.map((video) => formatExternalVideo(video)));
      } catch (_) {
        this.params.videos = allVideos;
      }
    },
    onVideo (video) {
      this.currentVideo = video;
      this.route = 'video';
      scrollUp();
    },
    onMenu () {
      this.route = 'menu';
    },
    onAmbassador (video) {
      saveAmbassador(video.hash);
    },
    onRate ({ video, rating }) {
      this.ratings = {
        ...this.ratings,
        [video.hash]: rating
      };
      saveRating(video.hash, rating);
    },
    onShare (video) {
      saveShare(video.hash);
    },
    onProduct (video) {
      window.open(video.url, "_blank");
      openVideoLink(video.url);
      saveProduct(video.hash);
    },
    onPage () {
      scrollUp();
    },
  },
};
</script>


<style scoped>
@import "../assets/styles/layout.css";
.opacity {
  transition: 0.3s ease;
}
.opacity0 {
  opacity: 0;
  visibility: hidden;
  display: none;
}
.opacity100 {
  opacity: 1;
  visibility: visible;
}
.opacity1Main {
  opacity: 1;
  visibility: visible;
  height: 100%;
}
.opacity0Main {
  opacity: 0;
  visibility: hidden;
  height: 400px;
}
.positionTopLeft {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
}
.notAvailable {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  transition: 0.3s ease;
}
.notAvailable .content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}
.yellowPerso {
  color: #f7a73f;
  font-size: 25px;
}
.nobold {
  font-weight: normal;
}
</style>
