<template>
    <div class="main">
        <div class="backNav">
            <div class="tap40" @click="onMenu">
                <back-icon class="icon"/>
                <p>{{ params.config.BACK }}</p>
            </div>
        </div>
        <iframe
            @load="onLoadIframe"
            ref="iframe"
            :src="`${playerUrl}#id=${video.hash}&vsg_id=${params.args.hash}&autoplay=1&allow_share=0&allow_rate=0&allow_ambassador=0&allow_buy=0&tracking_event=VSG_PLAY`"
            :class="{ vertical: video.ratio === '9:16' }"
            frameborder="0"
            allowfullscreen="1"
            allow="autoplay; fullscreen"
        ></iframe>

        <div class="name_video">
            {{ video.title || params.config.UNTITLED }}
        </div>

        <div class="select_actions">
            <div
                v-if="params.config.JOIN_URL"
                :class="{icon: 'icon', active : modal.amba}"
                @click="displayModal('amba')"
            >
                <ambassador ref="amba"/>
            </div>
            <div
                :class="{icon: 'icon', active : modal.rating}"
                @click="displayModal('rating')"
            >
                <star class="strokeFill" ref="rating"/>
            </div>
            <div
                :class="{icon: 'icon', active : modal.share}"
                @click="displayModal('share')"
            >
                <share ref="share"/>
            </div>
            <a
                class="icon"
                @click="onProduct"
                v-if="video.url"
                target="_blank"
            >
                <cart ref="cart"/>
            </a>
        </div>

        <div class="associat_video">
            <div class="title_header">{{ params.config.ALTERNATIVE_VIDEOS }}</div>
            <cardDisplayVideo
                v-for="(video, index) in suggestions"
                :key="video.id"
                :startAnimation="startAnimation"
                :transitionDelay="Math.abs(0.7 - (index / 10))"
                :video="video"
                :config="params.config"
                @click="onClick"
            />
        </div>

        <!-- Rating -->
        <div
            class="ratingDisplay modalOnPlayer"
            :class="[modal.rating ? 'opacity100' : 'opacity0']"
        >
            <div v-if="canRate" class="content">
                <p class="title-content">{{ params.config.RATING_TITLE }}</p>
                <p class="subtitle">{{ params.config.RATING_SUBTITLE }}</p>
                <div class="stars">
                    <starempty
                        v-for="n in 5"
                        :key="n"
                        :class="{fillNone: 'fillNone',strokeGray: 'strokeGray', active : rating >= n}"
                        @click="onRate(n)"
                    />
                </div>
                <button @click="onRateSubmit">{{ params.config.RATING_BUTTON }}</button>
            </div>
            <div v-else class="content">
                <p class="title-content">{{ params.config.RATING_THANKS }}</p>
            </div>
            <div class="bg" :style="{ backgroundImage: `url(${require('@/assets/blur.jpg')})` }"></div>
            <div @click="clearModal" class="close">
                <close/>
            </div>
        </div>

        <!-- Share -->
        <div
            class="shareDisplay modalOnPlayer"
            :class="[modal.share ? 'opacity100' : 'opacity0']"
        >
            <div class="content">
                <social-sharing :url="shareUrl" :networks="overriddenNetworks" inline-template @open="onShare">
                    <div>
                        <network network="facebook">
                            <font-awesome-icon
                                :icon="['fab', 'facebook-f']"
                                :style="{background: 'rgb(61,90,150)',color: 'white', 'font-size': '24px', width: '45px', height: '45px', 'padding': '12px', 'border-radius': '100%','box-shadow': 'rgba(0, 0, 0, 0.3) 7px 9px 28px -2px' }"
                            />
                        </network>
                        <network network="twitter">
                            <font-awesome-icon
                                :icon="['fab', 'twitter']"
                                :style="{background: 'rgb(57,154,213)',color: 'white', 'font-size': '24px', width: '45px', height: '45px', 'padding': '12px', 'border-radius': '100%','box-shadow': 'rgba(0, 0, 0, 0.3) 7px 9px 28px -2px','margin-left':'20px'  }"
                            />
                        </network>
                        <network network="whatsappFix">
                            <font-awesome-icon
                                :icon="['fab', 'whatsapp']"
                                :style="{background: 'rgb(50,186,70)',color: 'white', 'font-size': '30px', width: '45px', height: '45px', 'padding': '12px', 'border-radius': '100%','box-shadow': 'rgba(0, 0, 0, 0.3) 7px 9px 28px -2px', 'margin-left':'20px' }"
                            />
                        </network>
                    </div>
                </social-sharing>
            </div>
            <div class="bg" :style="{ backgroundImage: `url(${require('@/assets/blur.jpg')})` }"></div>
            <close class="close" @click="clearModal"/>
        </div>

        <!-- Ambassador -->
        <div
            class="ambaDisplay modalOnPlayer"
            :class="[modal.amba ? 'opacity100' : 'opacity0']"
        >
            <div class="content">
                <p class="title-content">{{ params.config.JOIN_TITLE }}</p>
                <p class="subtitle">{{ params.config.JOIN_SUBTITLE }}</p>
                <a
                    target="_blank"
                    @click="onAmbassador"
                    :href="params.config.JOIN_URL"
                >{{ params.config.JOIN_BUTTON }}</a>
            </div>
            <div class="bg" :style="{ backgroundImage: `url(${require('@/assets/blur.jpg')})` }"></div>
            <div @click="clearModal" class="close">
                <close/>
            </div>
        </div>

        <div class="imgTest" ref="image"></div>
    </div>
</template>

<script>
import share from "../assets/share.svg";
import cart from "../assets/basket.svg";
import star from "../assets/stars_half.svg";
import ambassador from "../assets/amba.svg";
import starempty from "../assets/stars_half.svg";
import close from "../assets/cross.svg";
import backIcon from "../assets/back_icon.svg";
import cardDisplayVideo from "../components/card_display_video";
import { PLAYER_URL } from "../config.js";
import { getSuggestions } from "../events.js";

export default {
    name: "Video",
    props: ["video", "params", "ratings"],
    data() {
        return {
            shareUrl: `${PLAYER_URL}?id=${this.video.hash}`,
            modal: {
                rating: false,
                share: false,
                amba: false
            },
            rating: 0,
            playerUrl: PLAYER_URL,
            startAnimation: false,
            suggestions: [],
            overriddenNetworks: {
                whatsappFix: {
                    sharer: `https://api.whatsapp.com/send?text=@url`,
                    type: "popup",
                    action: "share/whatsapp/share"
                }
            }
        };
    },
    components: {
        share,
        cart,
        star,
        ambassador,
        starempty,
        close,
        backIcon,
        cardDisplayVideo
    },
    computed: {
        canRate () {
            return !(this.video.hash in this.ratings);
        },
    },
    methods: {
        onMenu () {
            this.$emit("menu");
        },
        onAmbassador () {
            this.$emit('ambassador', this.video);
        },
        onRate (rating) {
            this.rating = rating;
        },
        onRateSubmit () {
            if (this.rating !== 0) {
                this.$emit('rate', { video: this.video, rating: this.rating });
                this.rating = 0;
                this.clearModal();
            }
        },
        onShare () {
            this.$emit('share', this.video);
        },
        onProduct () {
            this.$emit('product', this.video);
            this.clearModal();
        },
        pauseVideo: function() {
            this.$refs.iframe.contentWindow.postMessage(
                JSON.stringify({ type: "ACTION_PAUSE" }),
                `${this.playerUrl}#id=${
                    this.video.hash
                }&allow_share=0&allow_rate=0&allow_ambassador=0&allow_buy=0&vsg_id=${this.params.args.hash}tracking_event=VSG_PLAY`
            );
        },
        displayModal (type) {
            if (this.modal[type] || this.startAnimation) {
                this.clearModal();
            } else {
                this.clearModal();
                this.modal[type] = true;
                this.startAnimationForIcons(type);
                this.pauseVideo();
            }
        },
        startAnimationForIcons (type) {
            this.$refs[type].style.transform = "scale(0.4)";
            setTimeout(() => {
                this.$refs[type].style.transform = "scale(0.6)";
            }, 200);
        },

        clearModal () {
            Object.keys(this.modal).forEach(type => {
                this.modal[type] = false;
            });
        },
        onLoadIframe () {
            const image = this.$refs.image;
            setTimeout(() => {
                image.style.opacity = 0;
                image.style.visibility = "hidden";
                this.startAnimation = false;
            }, 500);
        },
        onClick ({ video, attribut: attr }) {
            this.clearModal();
            this.$refs.image.style.visibility = "visible";
            this.$refs.image.style.height = attr.height + "px";
            this.$refs.image.style.width = attr.width + "px";
            this.$refs.image.style.left = attr.x + "px";
            this.$refs.image.style.top = attr.y + "px";
            this.$refs.image.style.background = `url("${attr.src}")`;
            this.$refs.image.style["background-size"] = "cover";
            const imageRef = this.$refs.image;
            const iframe = this.$refs.iframe;
            setTimeout(() => {
                imageRef.style["border-radius"] = "0px";
                imageRef.style.left = "0px";
                imageRef.style.top = "30px";
                imageRef.style.width = "100%";
                imageRef.style.opacity = 100;
                imageRef.style.height = iframe.offsetHeight + "px";
                this.startAnimation = true;
                setTimeout(() => {
                    this.suggestions = getSuggestions(this.video, this.params.videos);
                    this.$emit("video", video);
                    setTimeout(() => {
                        const image = this.$refs.image;
                        if (image) {
                            image.style.opacity = 0;
                            image.style.visibility = "hidden";
                        }
                        this.startAnimation = false;
                    }, 5000);
                }, 500);
            }, 500);
        },
    },
    created() {
        this.suggestions = getSuggestions(this.video, this.params.videos);
    },
};
</script>

<style scoped>
.imgTest {
    background: red;
    background-size: cover;
    position: absolute;
    border-radius: 4px;
    transition: 0.5s ease;
    opacity: 0.3;
}
.opacity0 {
    opacity: 0;
    visibility: hidden;
}
.opacity100 {
    opacity: 100;
    visibility: visible;
}
.main {
    width: 100%;
    background: #f6f6f6;
}
.main iframe {
    width: 100%;
    height: 56.25vw;
}

.main iframe.vertical {
  aspect-ratio: 9/16;
  width: min-content;
  height: auto;
  margin: 0 auto;
  display: block;
}

.main .name_video {
    font-weight: 700;
    background: white;
    padding: 12px 20px;
    color: #707070;
    font-size: 12px;
}
.main .select_actions {
    margin: 10px 20px;
    background: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin-bottom: 30px;
    text-align: center;
}
.main .associat_video {
    margin: 10px 20px;
}
.main .associat_video .title_header {
    font-weight: 700;
    color: #363535;
    font-size: 12pt;
    margin-bottom: 10px;
    margin-left: 2px;
}
svg path {
    fill: inherit;
    stroke: inherit;
}
.strokeFill path {
    fill: none;
    stroke: rgb(0, 0, 0);
}
.strokeFillGray path {
    fill: none;
    stroke: #737171;
}

.widthSvg {
    transform: scale(0.1);
}

.main .select_actions .icon {
    display: inline-block;
    width: 25%;
    text-align: center;
    margin-top: 4px;
    position: relative;
}
.main .select_actions .icon svg {
    transform: scale(0.6);
    transition: 0.5s ease;
}
.scale05 {
    transform: scale(0.5);
}
.main .select_actions .icon::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    background: black;
    width: 0px;
    height: 2px;
    transition: 0.5s ease;
    transform: translateX(-50%);
}
.main .select_actions .icon.active::after {
    width: 35px;
}
.modalOnPlayer {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: 56.25vw;
    transition: 0.5s ease;
}
.modalOnPlayer .content {
    z-index: 10000;
    position: absolute;
}
.modalOnPlayer .bg {
    height: 101%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
}
.modalOnPlayer .close {
    fill: none;
    position: absolute;
    transform: scale(1.5);
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.modalOnPlayer .content {
    width: 100%;
    font-size: 9pt;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}
.shareDisplay .content {
    top: 39%;
    transform: inherit;
}
.modalOnPlayer .title-content {
    font-size: 12pt;
    color: black;
    font-weight: 700;
    margin-bottom: 5px;
}
.modalOnPlayer .subtitle {
    margin-bottom: 5px;
}
.ambaDisplay .subtitle {
    margin-top: 15px;
    margin-bottom: 25px;
}
.modalOnPlayer a,
.modalOnPlayer button {
    background: black;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 6px 20px;
    color: white;
    font-size: 14px;
    border-radius: 200px;
    text-decoration: inherit;
}
.ratingDisplay .stars {
    margin-bottom: 5px;
}
.stars > svg {
    transform: scale(0.9);
    margin: -5px;
    filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
}
.stars .active {
    fill: rgb(255, 230, 0);
    stroke: rgb(255, 230, 0);
}
.fillNone {
    fill: none;
}
.strokeGray {
    stroke: rgb(115, 113, 113);
}

.backNav {
    height: 30px;
}
.backNav p {
    font-size: 10px;
    font-weight: bold;
    display: inline-block;
    vertical-align: top;
    margin-top: 8px;
}
.backNav .icon {
    display: inline-block;
    vertical-align: top;
    transform: scale(0.5);
    margin-top: -6px;
}
.backNav .tap40 {
    width: 30%;
}
.ml110 {
    margin-left: 110%;
}
</style>
