<template>
      <div ref="cardThumbnail" class="cardThumbnail" @click="onClick" :style="{ transition : 'opacity ' + transitionDelay + 's ease'}" :class="{ml110: startAnimation, opacity0:startAnimation}">
            <img v-if="video.poster" ref="thumbImage" v-bind:src="video.poster" alt="" class="thumb inline thumbParent"/>
            <div v-else class="thumb inline backgroundBlack"/>
            <play class="play"/>
            <div class="titleThumb inline">
                  <p><b>{{ video.title || config.UNTITLED }}</b></p>
            </div>
      </div>
</template>

<script>
import play from "../assets/play.svg";

export default {
    name: "cardDisplayVideo",
    props: ["video", "config", "transitionDelay", "startAnimation"],
    components: {
        play,
    },
    methods: {
        onClick () {
            if (!this.startAnimation) {
                this.$refs.cardThumbnail.style["box-shadow"] = "inherit"
                const heightOfImage = this.$refs.thumbImage.clientHeight
                const widthOfImage = this.$refs.thumbImage.clientWidth
                const xOfImage = this.$refs.thumbImage.offsetLeft + this.$refs.thumbImage.offsetParent.offsetLeft
                const yOfImage = this.$refs.thumbImage.offsetTop + this.$refs.thumbImage.offsetParent.offsetTop
                const src = this.$refs.thumbImage.currentSrc
                const attributImage = {width : widthOfImage, height: heightOfImage, x: xOfImage, y: yOfImage, src:src}
                this.$emit("click", {video: this.video, attribut:attributImage})
            }
        }
  },
};
</script>

<style scoped>
.ml110{
      margin-left:-110%;
}
.opacity0{
      opacity:0 !important;
}
.thumbParent{
      display: inline-block;
      width: 60%;
      position: relative;
      border-radius:4px;
      overflow: hidden;
}
.play{
      position: absolute;
    top: 50%;
    left: 24%;
    transform: translateY(-50%);
}
.thumbParent .thumb,
.thumbParent .backgroundBlack{
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
}
.cardThumbnail .inline{
      display: inline-block;
      vertical-align: top;
}
.cardThumbnail{
      width: 100%;
      background: white;
      border-radius: 4pt;
      padding: 9pt;
      position: relative;
      opacity:1;
      margin-bottom:10pt;
      box-shadow: 20px 20px 20px 0px  rgba(0, 0, 0, 0.04);
      transition : .5s ease;
}
.cardThumbnail .titleThumb{
      margin-left: 15pt;
      margin-right: 15pt;
      font-size:9pt;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
}
.backgroundBlack{
      background: #000;
}
</style>
