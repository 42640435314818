import * as Sentry from '@sentry/browser';
import Vue from 'vue';
import SocialSharing from 'vue-social-sharing';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faFacebookF,
    faWhatsapp,
    faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { parseArgs, sendResize, openVideo, saveVSGHome, getSuggestions } from './events';
import { defineCustomElements } from '@skeepers/skeepers-ui-core/loader';

import './plugins/vuetify';
import App from './App.vue';
import { formatExternalVideo, formatProjectVideo } from './utils';

import { getExternalVideos, getSolutionConfiguration } from './graphql';

/* eslint-disable */

/* Sentry setup */
Sentry.init({ dsn: 'https://99cb264d73a74af693a19099b39752af@sentry.io/1420941' });

/* Import FontAwesome */
library.add(faFacebookF, faWhatsapp, faTwitter);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(SocialSharing);

defineCustomElements();

/* Get arguments */
// we add skp before categories to prevent potential conflict with client's website
export const args = { hash: '', origin: '', skpCategories: '', width: '1100px', ...parseArgs(window.location.hash) };

/* SDK overflow hidden required */
if (args.origin) {
    document.documentElement.style.overflow = 'hidden';
    var style = document.createElement('style');
    style.innerHTML = 'body, html{overflow: hidden;width: 100vw;}';
    document.documentElement.appendChild(style);
}

if (args.skpCategories) {
    args.skpCategories = args.skpCategories.split(',');
} 

export let allVideos = [];
let config = {};

const promiseVideos = [
    getSolutionConfiguration(args.hash, args.skpCategories),
    getExternalVideos(args.hash, args.skpCategories),
]

Promise.all(promiseVideos).then(([{ data }, { data: { externalVideos } }]) => {
    const { videos: videosRaw, configuration: configRaw, categories } = data.getSolution;

    allVideos = videosRaw
            .filter((video) => (video.poster) && video.productTitle)
            .map((video) => formatProjectVideo(video))
            .concat(externalVideos.edges.map((video) => formatExternalVideo(video))),


    allVideos.sort((a, b) => new Date(b.date) - new Date(a.date));

    config = configRaw.values.reduce((acc, { key, computedValue }) => ({ ...acc, [key.name]: computedValue }), {});

    if (args.skpCategories) {
        // Prevent categories not in the database from being used in the select
        config.DEFAULT_CATEGORIES = args.skpCategories.filter((argsCategory) => categories.find((category) => argsCategory === category));
    }

    /* Init Vue */
    Vue.config.productionTip = false;

    Vue.nextTick().then(() => {
        setTimeout(() => {
            sendResize();
        }, 0);
    });

    new Vue({
        render(h) {
            return h(App, { props: { args, videos: [...allVideos], config, categories } });
        }
    }).$mount('#app');

    // Tracking VSG Home
    saveVSGHome(args.hash);

    // Load font
    if (config.FONT_PATH) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = config.FONT_PATH;
        document.head.appendChild(link);
    }
});

/* SDK EVENT */
window.addEventListener('resize', () => {
    sendResize();
});
window.addEventListener('message', evt => {
    try {
        const e = JSON.parse(evt.data);

        // Resize Request
        if (e.type === 'RESIZE_REQUEST') {
            sendResize();
        }
        // The pop up was closed
        else if (e.type === 'POPUP_CLOSE') {
        }
        // Request to open another video
        else if (e.type === 'OPEN_VIDEO') {
            openVideo(e.data, getSuggestions(e.data, allVideos), args.hash);
        }
        else if (e.type === 'ROUTER_VIDEO') {
            // e.data => hash de la video
        }
        else if (e.type === 'ROUTER_HOME') {
            // Afficher la homepage
        }
        else if (e.type === 'CHANGE_ORIENTATION') {
            // e.data => orientation
        }
    } catch (e) { }
});

/* Force reload when hash changes */
window.onhashchange = () => {
    window.location.reload();
};
